.loader-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  flex-direction: column;

  .content {
    .right-shape {
      background-color: #e98100;
      height: 60px;
      width: 60px;
      border-radius: 81.2569px 81.2569px 81.2569px 0px;
      margin-left: 125px;
    }

    .left-shape {
      margin-top: 5px;
      height: 120px;
      width: 120px;
      background-color: black;
      background: #d9d9d9;
      border-radius: 56.7061px 0px 56.7061px 56.7061px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }
  }

  .text {
    h2 {
      margin-top: 1rem;
      font-family: "Bai Jamjuree", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: #1d1d1d;
    }
  }
}
