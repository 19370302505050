@tailwind base;
@tailwind components;
@tailwind utilities;
@import "node_modules/react-modal-video/scss/modal-video.scss";

*,
html,
body,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  scroll-behavior: smooth;
  font-size: 14.5px;
  @screen md {
    font-size: 16px;
  }
}
#root {
  height: inherit;
  --swiper-navigation-color: #fff;
  --swiper-navigation-size: 20px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-family: "Bai Jamjuree", sans-serif;
}

// @media (min-width: 760px) {
.scrollbar-style {
  &::-webkit-scrollbar {
    width: 0.375rem !important;
    height: 0.375rem !important;
    @apply bg-grey_90;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.25rem !important;
    @apply bg-secondary;
  }

  &.white-scrollbar {
    &::-webkit-scrollbar-thumb {
      border-radius: 0.25rem !important;
      @apply bg-white;
    }
  }
}

.scrollbar-style2 {
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    @apply bg-grey_90;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.25rem !important;
    @apply bg-primary;
  }

  &.white-scrollbar {
    &::-webkit-scrollbar-thumb {
      border-radius: 0.25rem !important;
      @apply bg-white;
    }
  }
}

.MuiButton-root.MuiLoadingButton-loading {
  .MuiCircularProgress-root {
    color: rgba(0, 0, 0, 0.466);
  }
  &.MuiButton-contained {
    background-color: rgba(0, 0, 0, 0.12);
  }
}
.MuiButtonBase-root.MuiButton-text {
  padding: 0.5rem !important;
}
.text-prop {
  line-height: 150%;
  letter-spacing: -0.03em;
}

button {
  &:active,
  :focus,
  :hover {
    border: none;
    outline: none;
  }
}

.box-shdw {
  box-shadow: 0px 12px 13px -6px rgba(0, 0, 0, 0.03);
}

.MuiInputBase-root {
  border: none !important;

  &.MuiOutlinedInput-root {
    border: none !important;
  }

  &.Mui-focused {
    border: none !important;
  }
}

.custom-dot-list-style li button {
  @apply h-2.5 w-2.5 border-none bg-black;
}

.custom-dot-list-style li.react-multi-carousel-dot--active button {
  @apply bg-primary;
}

.MuiTableHead-root {
  margin-bottom: 16px !important;
}

.MuiTabs-flexContainer {
  padding: 0px 8px !important;
}

.MuiTableBody-root {
  margin-top: 1rem !important;
  // display: flex !important;
  // flex-direction: column;
}

.MuiTableRow-root {
  border-spacing: 16px !important;
  // margin-top: 1rem !important;
}

.font-h1 {
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.04em;
  color: #3b3d3b;
  @screen md {
    font-size: 40px;
    line-height: 100%;
  }
}

.dashboard-overview {
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: -0.03em;
  color: #000000;
  border-bottom: 1px solid #f2f4f7;
  padding-bottom: 8px;
}

.font-h2 {
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: -0.03em;
  color: #000000;
}

.settings-font-h2 {
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #3b3d3b;
  @screen md {
    font-size: 32px;
    line-height: 100%;
  }
}

.settings-font-h3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 146.5%;
  letter-spacing: -0.025em;
  color: #2c2c3a;
  @screen md {
    font-size: 18px;
  }
}

.settings-font-p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #8585a3;
}

.red-dash-border-2px {
  border: 2px dashed #d92d20;
}

.green-dash-border-2px {
  border: 2px dashed #12b76a;
}

.grey-border-2px {
  border: 2px solid #f2f4f7;
}

.grey-border-1px {
  border: 1px solid #f2f4f7;
}

.MuiTabs-flexContainer {
  padding: 0 !important;
}

.word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

input:focus,
input:hover {
  border: none;
  outline: none;
}

// .cursor-drag {
//   .swiper-wrapper {
//     cursor: url("/assets/icons/drag_cursor.svg"), grab !important;
//   }
// }
.pointer-slider {
  .swiper-wrapper {
    cursor: pointer !important;
  }
}
.swiper-wrapper {
  padding-bottom: 3rem;
}
.swiper {
  .swiper-button-next,
  .swiper-button-prev {
    border-radius: 50%;
    width: 40px;
    box-shadow: 0px 30px 30px rgba(131, 9, 0, 0.05);
    height: 40px;
    @screen md {
      width: 50px;
      height: 50px;
    }
    background-color: #fff;
    transform: translateY(-50%);
    &:after {
      color: #000;
      font-weight: 700;
    }
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
}
.swiper-pagination {
  left: 0;
  bottom: 0 !important;

  .swiper-pagination-bullet {
    @apply bg-black;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      @apply bg-primary;
    }
  }
}
.category-card {
  position: relative;
  .content {
    position: relative;
    z-index: 4;
  }
  &::after {
    background: linear-gradient(180deg, transparent, #000);
    bottom: 0;
    content: "";
    height: 365px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    border-radius: 0 0 1.5rem 1.5rem;
  }
}
// Mui Selected Tab
// Mui Selected Tab
.MuiTabs-root {
  @apply rounded-16 border border-grey_60 bg-grey_80 p-2 md:w-fit;

  .MuiTabs-indicator {
    display: none;
  }
  .MuiTab-root {
    @apply text-lg md:text-xl;
    min-height: 36px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    @screen md {
      min-height: 48px;
      padding-top: 16px !important;
      padding-bottom: 16px !important;
    }
    &.Mui-selected {
      border: 1px solid #e4e7ec;
      background: white;
    }
  }
}

//MUI calendar
//MUI calendar
.MuiPickersCalendarHeader-label {
  font-family: "Bai Jamjuree", sans-serif;
  color: #ba6700;
  font-weight: 600;
}

.MuiTypography-root.MuiTypography-caption.MuiDayPicker-weekDayLabel {
  color: #ba6700 !important;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  border-radius: 12px;
  background: #e98100;
}

.border-style {
  border: 1px solid #e4e7ec;
}

.Dot-chip.MuiChip-root {
  border-radius: 8px;

  &.MuiChip-colorSuccess {
    background-color: #d1fadf;
    color: #039855;

    span::before {
      background-color: #039855;
    }
  }

  &.MuiChip-colorSecondary {
    background-color: #f3ecfd;
    color: #440e98;

    span::before {
      background-color: #440e98;
    }
  }

  &.MuiChip-colorError {
    background-color: #d92d201a;
    color: #d92d20;

    span::before {
      background-color: #d92d20;
    }
  }

  &.MuiChip-colorWarning {
    background-color: #fcecd9;
    color: #d27400;

    span::before {
      background-color: #d27400;
    }
  }

  span::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: inherit;
    display: inline-block;
    margin-right: 6px;
  }
}

.rdrDateRangeWrapper {
  background-color: #fcfcfd !important;
  border-radius: 20px;
  @apply max-w-full font-Mulish md:max-w-[450px];
  font-size: 16px;
  .rdrNextPrevButton,
  .rdrNextButton {
    background-color: #e4e7ec !important;
  }
  .rdrMonth {
    width: 100%;
  }
  .rdrDay,
  * {
    @apply font-Mulish text-xs md:text-base;
  }
  .rdrDayToday .rdrDayNumber span:after {
    background-color: #e98100;
  }
  .rdrInRange {
    background-color: #fdf2e6;
  }
  .rdrInRange ~ .rdrDayNumber {
    color: #e98100 !important;
    span {
      color: #e98100 !important;
    }
  }
  .rdrDateDisplay {
    margin-bottom: 0;
    flex-wrap: wrap;
  }
  .rdrDateDisplayWrapper {
    background-color: transparent;
    .rdrDateInput {
      border-radius: 10px;
      @apply border-grey_60;
      box-shadow: none;
      input {
        height: 3rem;
      }
    }
  }
}

.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-weight: medium !important;
  font-family: Mulish, sans-serif !important;
  font-size: 16px !important;
  color: #3b3d3b !important;
}

.MuiTabPanel-root {
  padding: 0px 0 !important;
}
.filter-select {
  box-shadow: 0px 30px 30px rgba(131, 9, 0, 0.05);
  border-radius: 25px;
}
.custom-bg {
  background-image: url("assets/images/bg-1.png"),
    linear-gradient(
      90deg,
      rgba(59, 61, 59, 1) 0%,
      rgba(59, 61, 59, 1) 50%,
      rgba(0, 0, 0, 0.3) 100%
    );
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100%;
  // background-color: linear-gradient(
  //   0deg,
  //   rgba(0, 0, 0, 0.5),
  //   rgba(0, 0, 0, 0.5)
  // );
  background-blend-mode: overlay;
}

// .MuiModal-root {
//   backdrop-filter: blur(5px);
//   -webkit-backdrop-filter: blur(5px);
// }

.myBgOverlay {
  background: linear-gradient(
    0deg,
    #000000 -4.88%,
    rgba(0, 0, 0, 0) 89.6%
  ) !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 24px;
  // opacity: 1;
  // transition: .3s ease;
}

.dropdown-boxShadwn {
  box-shadow: -23px 54px 59px rgba(0, 0, 0, 0.04),
    -6px 14px 32px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
}

.noScrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &.white-scrollbar {
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
}

.dropdown-transition {
  transition: transform 0.2s ease;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}
