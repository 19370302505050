.modal-wrap {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  // z-index: 1000;
  overflow: hidden;
  // backdrop-filter: blur(5px);
  @apply z-50 flex items-center justify-center;

  .close-modal-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    @apply absolute top-3 right-3 z-30 md:top-6 md:right-6;
  }

  .modal-title {
    margin-bottom: 1rem;
    @apply font-Bai text-xl text-grey md:text-2xl;
    // font-size: 24px;
    font-weight: 600;
  }

  .modal-content {
    position: relative;
    // top: 0;
    // right: 0;
    display: flex;
    flex-direction: column;
    // width: 95vw;
    background: #ffffff;
    border-radius: 20px;
    @apply p-4 md:p-6;
    margin: auto;
    max-height: 90vh;
    overflow-y: auto;
    width: 90%;

    .btn-wrap {
      display: flex;
      justify-content: flex-end;

      button {
        min-width: 118px;
      }
    }
  }

  .msg-modal {
    max-width: 430px;
    min-height: 320px;
    border-radius: 35px;
    @apply py-10 px-6;

    .icon-wrap {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      @apply flex items-center justify-center;

      svg {
        font-size: 2.5rem;
        @apply h-12 w-12 md:h-14 md:w-14;
      }
    }

    align-items: center;
    justify-content: center;
    // gap: 1rem;
    text-align: center;

    .title {
      @apply font-Bai text-grey;
      font-size: 32px;
      margin: 0;
      font-weight: 500;
    }

    button {
      margin-top: auto;
    }
  }
}

.application_modal {
  background: #fff;
  @apply p-8 md:p-10;
  padding-top: 20px;
  width: 100%;
  flex-grow: 2;
}
