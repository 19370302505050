.form-group-2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.85rem;

  label {
    font-size: 1rem;
    font-weight: 400;
    color: #6e726e;
  }

  input {
    border: none;
    outline: none;
    background: #f9fafb;
    width: 100%;
    padding: 12px 1rem;
    border-radius: 4px;

    &:focus {
      border: none;
      background: #e4e7ec;
    }

    &::placeholder {
      font-size: 14px;
      color: #aaaaaa;
    }
  }
}
.darkBgForm {
  .form-group {
    background: #f2f4f7 !important;
  }
}

.dragDrop {
  margin-top: 8px;
  display: flex;
  column-gap: 8px;
  max-width: 90%;
  svg {
    height: 36px;
    width: 36px;
    path {
      fill: #e98100;
    }
  }
  div {
    display: flex;
    flex-direction: column;
    span {
      color: black;
      font-size: 1rem;
      font-weight: 700;
      @media screen and (min-width: 768px) {
        & {
          font-size: 0.8rem;
        }
      }
      span {
        text-decoration: none;
        color: #e98100;
      }
    }
    &::after {
      content: ".jpg, .jpeg, .png, or .pdf not more than 1mb";
      width: 100%;
      color: #a2a2b9;
      font-size: 0.8rem;
      @media screen and (min-width: 768px) {
        & {
          font-size: 0.75rem;
        }
      }
    }
  }
}
