.empty-response-pg {
  text-align: center;
  @apply flex flex-col items-center justify-center;
  margin: 3rem auto;
  height: 100%;

  img {
    max-width: 162px;
    max-height: 168px;
  }

  p.title {
    font-size: 24px;
    font-weight: 600;
    @apply text-grey_20 font-Bai;
    margin: .4rem auto;
    text-align: center;
    max-width: 350px;
  }

  .icon-wrap {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    @apply flex items-center justify-center;

    svg {
      font-size: 2.5rem;
      width: 60px;
      height: 60px;
    }
  }
}