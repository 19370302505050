// @use "../../assets/styleUtils/" as *;

.drop-down-wrapper {
  position: relative;
  .drop-down-content {
    width: fit-content;
    position: absolute;
    // top: 120%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    background-color: white;
    transform: scale(0);
    transition: transform 0.2s ease;
    width: max-content;
    min-width: 150px;
    z-index: 20;
    &.show-drop-down {
      transform: scale(1);
    }
    .hr {
      width: 100%;
      border: 1px solid #edf2f7;
    }
    button,
    a {
      display: flex;
      width: 100%;
      text-align: left;
      justify-content: flex-start;
      // margin-top: 0.8rem;
      font-weight: 500;
      // font-size: 0.875rem;
      &:first-child {
        margin-top: 0;
      }
      &:hover {
        background: none !important;
      }
    }
  }
}
