.PhoneInput {
  .PhoneInputInput {
    border: none;
    outline: none;
    background: #F9FAFB;

    &:focus,
    :focus-within,
    :focus-visible {
      border: none;
      outline: none;
    }
  }
}
