.BuyerDashboard {
  overflow-y: auto;
  max-height: 100vh;
  .buyer-search {
    border-color: #dcdce51a;
    color: white;
    ::placeholder {
      color: #dcdce5;
    }
  }
  .inner {
    @apply w-[92%] max-w-[1400px] mx-auto;
    &.card-inner {
      max-width: 1190px;
    }
  }
  .sub-title {
    @apply text-[#2C2C3A] text-2xl font-semibold my-3 lg:text-3xl;
  }
  .title {
    @apply text-2xl lg:text-4xl font-bold;
  }
}
