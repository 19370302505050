.otp-input-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
  .input-container {
    width: 4rem;
    border: 1.5px solid #e3e3e3;
    height: 54px;
    border-radius: 5px;
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus-within {
      @apply border-[2px] border-primary;
    }
    &.invalid {
      @apply border-error;
    }
  }

  input {
    width: 24px;
    text-align: center;
    border: none;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.2;
    background-color: transparent;
    outline: none;

    &:focus {
      border-bottom: 1px solid;
      @apply border-primary;
    }
  }
}
