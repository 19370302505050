.landing-page-container {
  .header {
    // height: 100vh;
    background-image: url(../../../public/images/header_bg.png);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    background-size: contain;

    .input input {
      border: none;
      outline: none;
      background: none;
      width: 100%;

      &::placeholder {
        color: #777777;
        font-weight: 400;
        font-size: 18px;
      }
    }
  }

  .question {
    // height: 100vh;
    background-image: url(../../../public/images/question-bg.png);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    background-size: contain;

    .input input {
      border: none;
      outline: none;
      background: none;
      width: 100%;

      // &::placeholder {
      //     color: #1d1d1d;
      //     font-weight: 400;
      //     font-size: 16px;
      // }
    }
  }

  .earn-section {
    // min-height: 100vh;
    background-image: url(../../../public/images/earn-bg.png);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    background-size: contain;
  }
  .skill-card {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .mobile-app {
    min-height: 40vh;
    background-image: url(../../../public/images/hand-holding-phone.png);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    background-size: contain;
  }
}
.categories-slider {
  .category-card {
    width: 55% !important;
    min-height: 403px;
    padding-bottom: 2rem;
    @apply ml-auto flex justify-center items-start rounded-3xl transition-all duration-700;
    .category-desc {
      @apply opacity-0;
    }

    &.active {
      width: 100% !important;
      @screen md {
        width: 167% !important;
      }
      @apply p-6 md:p-10;

      .category-desc {
        @apply opacity-100 transition-opacity delay-500 duration-700;
      }
    }
    &.next {
      transform: translateX(60%);
    }

    @keyframes displayTitle {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  .swiper-slide-next.after-next + .swiper-slide .category-card {
    transform: translateX(10px);
  }
  .swiper-slide-next.after-next + .swiper-slide + .swiper-slide .category-card {
    margin-right: auto;
  }
  .swiper-pagination {
    text-align: left;
  }
}
.testimonials-slider {
  .swiper-slide:first-of-type {
    @apply md:mx-24;
  }
  .swiper .swiper-button-next {
    right: 1rem;
  }
  .swiper .swiper-button-prev {
    left: 1rem;
  }
  // @screen md {
  //   .swiper {
  //     padding-right: 6rem !important;
  //   }
  // }
}

.testimonials {
  background: linear-gradient(#eeeeee 20%, rgba(238, 238, 238, 0) 80%);
}

.category-small-type {
  // orientation: landscape;
  transform: rotate(-90deg);
}

.noScroll::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .landing-page-container {
    .header {
      background-image: none;
    }

    .earn-section {
      background-image: none;
    }

    .mobile-app {
      background-image: none;
    }
  }
}
.hideScroll {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
}
.custom-slider {
  height: 90vh;
  img {
    object-fit: cover;
    aspect-ratio: 16/9;
    object-position: left;
  }
  .slide {
    @apply absolute left-0 bottom-0 top-0;
    transform: translateX(150%);
    min-height: 650px;
    overflow: hidden;
    transition: opacity 1s ease 1s;
    .hero-img {
      transition: opacity 1s ease;
      min-height: 350px;
      // border-radius: 150px 0px 0px 150px;
      // -webkit-border-radius: 150px 0px 0px 150px;
      // -moz-border-radius: 150px 0px 0px 150px;
      @screen md {
        min-height: 600px;
      }
      @screen lg {
        min-height: 640px;
      }
      @screen xl {
        min-height: 679px;
      }
    }
    opacity: 1;
    z-index: 1;
    width: 100%;
    .pattern {
      transition: left 0.7s ease-in-out 0.2s;
      left: 95%;
      height: 100%;
      overflow-y: hidden;
      width: 100%;
    }
    &.prev {
      opacity: 0;
      .hero-img {
        opacity: 0;
      }
      transform: translate(0);
      .pattern {
        left: 0;
      }
    }
    &.active {
      animation: slideIn 0.7s ease-in-out 0.3s forwards;
      z-index: 100;
      // opacity: 1;
      // .hero-img {
      //   opacity: 1;
      // }
      // transform: translate(0);
      // .pattern {
      //   left: 95%;
      // }
    }
  }

  @keyframes slideIn {
    from {
      transform: translateX(150%);
    }
    to {
      transform: translateX(0);
    }
  }
}
.header-slider {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 311px;
    background: rgba(0, 0, 0, 0.437);
    opacity: 0.5;
    filter: blur(50px);
    // border-radius: 56px;
    top: 35%;
    left: 23%;
    width: 50%;
    @screen lg {
      top: 40%;
    }
    @screen xl {
      top: 47%;
    }
  }
}
.scrolling-words-box {
  height: 1.2em;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
  font-size: inherit;
  // text-align: center;
  margin-right: 0.6rem;
  transform: translateY(10px);
  display: inline-block;
}
.scrolling-words-box > span {
  position: absolute;
  top: 0;
  left: 0;
  transition: top ease 0.5s;
}
