.form-group {
  @apply relative flex h-14 flex-grow items-center justify-between px-4 focus:bg-red-700;
  &.select-group {
    height: unset;
    label {
      position: unset;
      @apply w-full text-sm font-medium text-grey_30;
    }
  }
  &.textarea-wrap {
    @apply px-0;
    height: unset;
    textarea {
      @apply py-2 px-4;
    }
  }
  label {
    @apply absolute left-2 bg-none;
    transition: all 0.3s ease-out;
  }

  .icon-btn {
    @apply flex h-6 w-6 items-center justify-center;
  }
  > svg {
    width: 100%;
    height: 100%;
  }
  &.invalid {
    @apply border-error focus-within:border-error;
  }
}
.form-group-wrapper {
  @apply flex-grow;
}
.input-err-msg {
  @apply mt-1 text-[10px] text-error;
  width: 100%;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: right;
}
.no-border {
  background-color: #f9fafb;
  border-radius: 16px;

  .MuiOutlinedInput-root {
    min-height: 56px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}
.MuiAutocomplete-input {
  padding: 0 7.5px !important;
}
.select-mui {
  padding: 0;
  width: 100%;
  svg {
    fill: #858c94;
  }
  .MuiChip-root {
    border-radius: 5px !important;
  }
  .MuiAutocomplete-popper {
    border-radius: 12px !important;
    border: 1px solid #eaeaf0 !important;
    box-shadow: 0px 8px 39px rgba(56, 21, 86, 0.08) !important;
  }

  .MuiOutlinedInput-root .MuiAutocomplete-input {
    font-size: 1rem !important;
    font-family: "Bai Jamjuree", sans-serif !important;

    &::placeholder {
      font-size: 14px !important;
      font-weight: 400 !important;
      font-family: "Bai Jamjuree", sans-serif !important;
    }
  }

  &.autocomplete {
    .MuiOutlinedInput-root {
      min-height: 3.5rem;
      height: auto;
    }
  }
}
.menu-item {
  font-size: 0.9rem !important;
  &.placeholder {
    color: #b5b5bd;
  }
}

.MuiAutocomplete-input {
  input {
    height: 3.5rem !important;
    font-size: 0.5rem !important;
    font-family: "Bai Jamjuree", sans-serif !important;
  }
}

.dp-upload {
  margin: 10px 0;

  .label {
    @apply h-16 w-16 md:h-20 md:w-20;

    border-radius: 50%;
    background: #f5f6fd;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    position: relative;
    .selectedImg {
      overflow: hidden;
      position: absolute;
      inset: 0;
      width: 100%;
      border-radius: 50%;
      height: 100%;
      object-fit: cover;
    }
    .editIcon {
      @apply h-9 w-9 bg-primary md:h-12 md:w-12;
      position: absolute;
      bottom: -0.1rem;
      right: 0px;
      padding: 0.4rem;
      margin: 0;
      border-radius: 50%;
      min-width: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

.formGroupBg {
  background: white !important;
}

.radio {
  width: 100%;

  &__label {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: -0.5em;
    margin-right: 1em;
    vertical-align: middle;
  }

  &__wrapper {
    @apply text-primary;
    padding: 0.6rem;
    flex: 0 0 auto;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;

    &:hover {
      background-color: rgb(233 129 0 / 0.08);
    }
  }

  &__custom-input {
    display: flex;
    position: relative;

    &__svg {
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 1.5rem;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
    }

    &__inside {
      transform: scale(1);
      transition: transform 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      left: 0;
      position: absolute;
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 1.5rem;
      flex-shrink: 0;
      user-select: none;
    }
  }

  &__input {
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
  }

  &__input:not(:checked) + &__wrapper {
    color: rgba(0, 0, 0, 0.54);

    .radio__custom-input__inside {
      transform: scale(0);
      transition: transform 150ms cubic-bezier(0.4, 0, 1, 1) 0ms;
    }
  }
}
